import { TreeNode } from 'primereact/treenode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'shared/lib/collections';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import ProjectTreeSelector, { CustomizedTreeNode, EntityWithProject } from '../../elements/ProjectTreeSelector';
import { ReactComponent as expandedCubes } from '../../images/cubes-expanded.svg';
import { ReactComponent as cubes } from '../../images/cubes.svg';
import useParts from '../hooks/useParts';
import { getPartId } from '../lib/assemblyParts';
import { getReleasedRevisions } from '../lib/part_revisions';
import { isPartRestricted } from '../lib/parts';
import './styles/TreeSelect.css';
import { PartSelection } from './PartAndRevisionIdSelect';

type PartNodeEntity = Omit<TreeNode, 'id'> & EntityWithProject;

export const getPartAsSelection = (part?: Part | null): PartSelection | null => {
  if (!part) {
    return null;
  }
  const revision = (part.revisions || []).find((revision) => revision.revision === part.rev);
  const partRevisionId = revision?.id || '';
  return { part, partRevisionId };
};

const sortNodesAlphabetically = (nodes: TreeNode[]): TreeNode[] => {
  const sortedNodes = sortBy(nodes, ['label']);
  sortedNodes.forEach((node) => {
    if (node.children && node.children.length > 0) {
      node.children = sortNodesAlphabetically(node.children);
    }
  });

  return sortedNodes;
};

const findNonAssemblyParts = (parts: Part[], partNumbersSet: Set<string>): Part[] => {
  return parts.filter((part) => !isPartRestricted(part) && !partNumbersSet.has(part.part_no));
};

type PartSelectProps = {
  selected: PartSelection | null;
  projectId?: string;
  onSelect: (selection: PartSelection | null) => void;
  /** @todo use this property once primereact is upgraded to v10 */
  canClearPart?: boolean;
  isDisabled?: boolean;
  shouldDisablePartSelect?: (part: Part | ComponentPart) => boolean;
};

const PartSelect = ({
  selected,
  projectId,
  onSelect,
  canClearPart = true,
  isDisabled = false,
  shouldDisablePartSelect = undefined,
}: PartSelectProps) => {
  const { parts, assemblyParts, getPart } = useParts();
  const [nodes, setNodes] = useState<TreeNode[]>([]);

  const mapPartToTreeSelectNodes = useCallback(
    (part: Part | ComponentPart, includeComponents: boolean, parentPathId = '') => {
      const partId = getPartId(part);
      const nodeKey = parentPathId === '' ? partId : `${parentPathId}-${partId}`;
      const node: CustomizedTreeNode = {
        key: nodeKey,
        label: isPartRestricted(part) ? 'Restricted' : `${part.part_no} ${part.name}`,
        children: [],
        data: part,
        selectable: shouldDisablePartSelect ? !shouldDisablePartSelect(part) : true,
      };
      if (includeComponents && part.components && part.components.length > 0) {
        node.children = part.components.map((component) => mapPartToTreeSelectNodes(component, true, nodeKey));
        node.expandedIcon = expandedCubes;
        node.collapsedIcon = cubes;
      }
      return node;
    },
    [shouldDisablePartSelect]
  );

  useEffect(() => {
    if (!parts || !assemblyParts) {
      return;
    }
    const partNumbersSet = new Set<string>();
    for (const assemblyPart of assemblyParts) {
      partNumbersSet.add(assemblyPart.part_no);
    }
    const nonAssemblyParts = findNonAssemblyParts(parts, partNumbersSet);
    const assemblyPartNodes = assemblyParts.map((assemblyPart) => mapPartToTreeSelectNodes(assemblyPart, true));
    const nonAssemblyPartNodes = nonAssemblyParts.map((nonAssemblyPart) =>
      mapPartToTreeSelectNodes(nonAssemblyPart, false)
    );

    setNodes([...sortNodesAlphabetically(assemblyPartNodes), ...sortNodesAlphabetically(nonAssemblyPartNodes)]);
  }, [assemblyParts, mapPartToTreeSelectNodes, parts]);

  const selectedNodeKey = useMemo(() => {
    if (!selected) {
      return null;
    }
    if (selected.nodeKeyId) {
      return selected.nodeKeyId;
    } else if (selected.part) {
      return getPartId(selected.part);
    }
    return null;
  }, [selected]);

  const partEntities = useMemo(() => {
    const mapped = nodes.map((node: TreeNode) => ({
      ...node,
      ...node.data,
      id: node.data?.part_id ?? node.id ?? node.data?.id ?? '',
      key: node.key ?? node.data?.key ?? '',
      projectId: node.data?.project_id,
    })) as Array<PartNodeEntity>;

    return mapped;
  }, [nodes]);

  const labelFormatter = (part: PartNodeEntity) => part.label ?? '';

  const onNodeSelect = useCallback(
    (key?: string | number, node?: PartNodeEntity) => {
      if (!node || !key) {
        return;
      }
      const partId = getPartId(node as Part);
      const part = getPart(partId);
      if (!part) {
        return;
      }
      const released = getReleasedRevisions(part.revisions || []);
      if (released.length < 1) {
        return;
      }
      const partRevisionId = released[0].id;
      onSelect({ part, partRevisionId, nodeKeyId: key as string });
    },
    [getPart, onSelect]
  );

  return (
    <ProjectTreeSelector
      entities={partEntities}
      currentEntityId={selectedNodeKey ?? undefined}
      currentProjectId={projectId}
      labelFormatter={labelFormatter}
      placeholder="Search parts"
      isDisabled={isDisabled}
      onSelect={onNodeSelect}
      sortChildren={false}
    />
  );
};

export default PartSelect;
